import classNames from 'classnames'
import React from 'react'
import queryString from 'query-string'
import { IncorretResult } from '../../../../components/IncorretResult'
import { Pagination } from '../../../../components/Pagination'
import { FilterModule } from '../../../../modules/FilterModule'
import { isMobileDevice } from '../../../../utils/check'
import { MODERATION_HISTORY_SIZE } from '../../ModerationHistoryPage-constants'
import { useFetchModerationHistory } from '../../ModerationHistoryPage-hooks'
import { ModerationHistoryAPIAnswerType } from '../../ModerationHistoryPage-types'
import { ModerationHistoryMobile } from '../ModerationHistoryMobile'
import { ModerationHistoryTable } from '../ModerationHistoryTable'
import { SkeletonBody } from '../../../../components/SkeletonBody'
import { MODERATION_PATH } from '../../../../constants/globalConstants'
import { useURLParams } from '../../../../hooks/useURLParams'
import { useNavigate } from 'react-router'
import {
  INTERVAL_URL_VALUE,
  MATERIAL_STATUS_URL_VALUE,
  PAGE_URL_VALUE,
} from '../../../../constants/filterURLNames'

export const ModerationHistory = () => {
  const urlParams = useURLParams()
  const navigate = useNavigate()
  const interval = urlParams[INTERVAL_URL_VALUE]
  const status = urlParams[MATERIAL_STATUS_URL_VALUE]
  const page = urlParams[PAGE_URL_VALUE]
  const currentPage = page ? +page : 1

  const isMobile = isMobileDevice()

  const {
    data: moderationHistoryRequestData,
    isLoading,
    isFetching,
    isError,
  } = useFetchModerationHistory(currentPage, status, interval) as {
    data: ModerationHistoryAPIAnswerType
    isFetching: boolean
    isLoading: boolean
    isError: boolean
  }

  const moderationHistoryList = React.useMemo(
    () => moderationHistoryRequestData?.items,
    [moderationHistoryRequestData]
  )
  const total = moderationHistoryRequestData?.total
  const pageCount = React.useMemo(() => {
    return Math.ceil(total / MODERATION_HISTORY_SIZE)
  }, [total])

  const handleChangePage = (page: string) => {
    urlParams[PAGE_URL_VALUE] = (+page + 1).toString()
    navigate({ search: queryString.stringify(urlParams) })
  }

  const handleRowClick = (id: string) => {
    navigate(`${MODERATION_PATH}?moderationId=${id}`)
  }

  if (isLoading) {
    const mode = isMobile === true ? 'windows' : 'table'
    return (
      <SkeletonBody
        mode={mode}
        columnsCount={5}
        actionMode={'pagination'}
        withFilters={true}
      />
    )
  }
  if (isError) {
    return <IncorretResult />
  }

  const bodyCls = classNames('list-data-wrapper', {
    'list-data-wrapper_fetching': isFetching === true,
  })

  return (
    <div className={bodyCls}>
      <FilterModule
        title={'История модерации'}
        withMaterialStatus
        withDateInterval
        className='list-data-wrapper__filters'
      />
      {isMobile ? (
        <ModerationHistoryMobile
          moderationHistoryList={moderationHistoryList}
          handleRowClick={handleRowClick}
        />
      ) : (
        <ModerationHistoryTable
          moderationHistoryList={moderationHistoryList}
          handleRowClick={handleRowClick}
        />
      )}
      <div className='list-data-wrapper-actions'>
        {pageCount > 1 && (
          <Pagination
            className='list-data-wrapper-actions__pagination'
            activeId={`${currentPage - 1}`}
            onChange={handleChangePage}
            count={pageCount}
          />
        )}
      </div>
    </div>
  )
}
