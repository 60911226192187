import { format, parseISO } from 'date-fns'
import { ru } from 'date-fns/locale'
import {
  ROLE_ADMIN,
  ROLE_CONSUMER,
  ROLE_CONTRIBUTOR,
} from '../constants/common'
import {
  BANK_PHOTO_PATH,
  BANK_REPORTS_PATH,
} from '../constants/globalConstants'
import {
  MATERIAL_STATUS_ENUM,
  ORDER_STATUS_ENUM,
  USER_STATUS_ENUM,
} from '../types/enums'

export const getStatus = (status: string) => {
  if (status === USER_STATUS_ENUM.ACTIVE) return 'Активен'
  if (status === USER_STATUS_ENUM.DISABLED) return 'Удален'
  if (status === USER_STATUS_ENUM.LOCKED) return 'Заблокирован'
}

export const getUserType = (type: string) => {
  if (type === ROLE_CONSUMER) return 'Потребитель'
  if (type === ROLE_CONTRIBUTOR) return 'Поставщик'
  if (type === ROLE_ADMIN) return 'Администратор'
  else return 'Неизвестно'
}

export const getMaterialStatus = (status: string) => {
  if (status === MATERIAL_STATUS_ENUM.NEW) return 'Новый'
  if (status === MATERIAL_STATUS_ENUM.WAITING) return 'Ожидает решения'
  if (status === MATERIAL_STATUS_ENUM.ACCEPT) return 'Опубликован'
  if (status === MATERIAL_STATUS_ENUM.DENY) return 'Отклонен'
  else return status
}

export const getOrderStatus = (status: string) => {
  if (status === ORDER_STATUS_ENUM.NEW) return 'Новая'
  if (status === ORDER_STATUS_ENUM.WAITING) return 'Модерация'
  if (status === ORDER_STATUS_ENUM.ACCEPT) return 'Одобрено'
  if (status === ORDER_STATUS_ENUM.DENY) return 'Отказано'
  else return status
}

export const isStatusValid = (orderStatus: string) => {
  return (
    orderStatus === ORDER_STATUS_ENUM.NEW ||
    orderStatus === ORDER_STATUS_ENUM.WAITING
  )
}

export const getRawPathFromHash = (hash: string) => {
  if (hash) {
    return `${BANK_PHOTO_PATH}/raw/${hash.slice(0, 2)}/${hash.slice(
      2,
      4
    )}/${hash}.jpg`
  } else {
    return ''
  }
}
export const getProtectedPathFromHash = (hash: string) => {
  if (hash) {
    return `${BANK_PHOTO_PATH}/protected/${hash.slice(0, 2)}/${hash.slice(
      2,
      4
    )}/${hash}.jpg`
  } else {
    return ''
  }
}

export const getPathToReportFromHash = (hash: string) => {
  if (hash) {
    return `${BANK_REPORTS_PATH}/${hash.slice(0, 2)}/${hash.slice(
      2,
      4
    )}/${hash}.docx`
  } else {
    return ''
  }
}

export const getNormalizeDate = (date: string | undefined) => {
  if (date) {
    return format(parseISO(date), 'dd.MM.yyyy', {
      locale: ru,
    })
  } else {
    return 'Неизвестно'
  }
}

export const getRubles = (value: string | number) => {
  if (value >= 0) {
    return `${value} ₽`
  } else {
    return 'Неизвестно'
  }
}

// export const getListIdsFromMaterials = (items: MaterialsType) => {
//   let ids = items.reduce(function (newArr: number[], item: MaterialType) {
//     newArr.push(item.id)
//     return newArr
//   }, [])
//   return ids
// }

export const getNowDate = (presentForm: 'dash' | 'dots' = 'dots') => {
  const dateFormat = presentForm === 'dash' ? 'yyyy-MM-dd' : 'dd.MM.yyyy'
  return format(new Date(), dateFormat, {
    locale: ru,
  })
}

export const getPrevMonthDate = () => {
  const changingDate = new Date()
  changingDate.setDate(1)
  changingDate.setMonth(changingDate.getMonth() - 1)
  return changingDate
}

export const getDateFromISO = (date: string) => {
  return date.split('T')[0]
}
