import { GROUP_CONSUMERS_API_PATH } from '../../constants/globalConstants'
import { instance } from '../../utils/coreAPI'
import { GROUPS_SIZE } from './ConsumerGroupPage-constants'

export const fetchConsumerGroupList = async (page: number) => {
  try {
    const response = await instance.get(GROUP_CONSUMERS_API_PATH, {
      params: {
        page,
        size: GROUPS_SIZE,
      },
    })
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
