import classNames from 'classnames'
import React from 'react'
import queryString from 'query-string'
import { Button } from '../../../../components/Button'
import { IncorretResult } from '../../../../components/IncorretResult'
import { Pagination } from '../../../../components/Pagination'
import { isMobileDevice } from '../../../../utils/check'
import { MODERATORS_SIZE } from '../../ModeratorsPage-constants'
import { useFetchModerators } from '../../ModeratorsPage-hooks'
import { ModeratorsMobile } from '../ModeratorsMobile'
import { ModeratorsTable } from '../ModeratorsTable'
import { FilterModule } from '../../../../modules/FilterModule'
import { SkeletonBody } from '../../../../components/SkeletonBody'
import { useNavigate } from 'react-router'
import { useURLParams } from '../../../../hooks/useURLParams'
import {
  INTERVAL_URL_VALUE,
  NAME_URL_VALUE,
  PAGE_URL_VALUE,
  USER_STATUS_URL_VALUE,
} from '../../../../constants/filterURLNames'
import { ModeratorAPIAnswerType } from '../../../../types/moderator'

export const Moderators = () => {
  const navigate = useNavigate()
  const urlParams = useURLParams()
  const interval = urlParams[INTERVAL_URL_VALUE]
  const name = urlParams[NAME_URL_VALUE]
  const status = urlParams[USER_STATUS_URL_VALUE]
  const page = urlParams[PAGE_URL_VALUE]
  const currentPage = page ? +page : 1

  const isMobile = isMobileDevice()
  const {
    data: moderatorsRequestData,
    isLoading,
    isFetching,
    isError,
  } = useFetchModerators(currentPage, status, interval, name) as {
    data: ModeratorAPIAnswerType
    isFetching: boolean
    isLoading: boolean
    isError: boolean
  }

  const moderatorsList = React.useMemo(
    () => moderatorsRequestData?.items,
    [moderatorsRequestData]
  )
  const total = moderatorsRequestData?.total
  const pageCount = React.useMemo(() => {
    return Math.ceil(total / MODERATORS_SIZE)
  }, [total])

  const handleRowClick = (id: string) => {
    navigate(`${id}/edit`)
  }

  const handleChangePage = (page: string) => {
    urlParams[PAGE_URL_VALUE] = (+page + 1).toString()
    navigate({ search: queryString.stringify(urlParams) })
  }

  const goToCreateModerator = () => {
    navigate(`new`)
  }

  if (isLoading) {
    const mode = isMobile === true ? 'windows' : 'table'
    return (
      <SkeletonBody
        mode={mode}
        columnsCount={5}
        actionMode={'all'}
        withFilters={true}
      />
    )
  }
  if (isError) {
    return <IncorretResult />
  }

  const bodyCls = classNames('list-data-wrapper', {
    'list-data-wrapper_fetching': isFetching === true,
  })

  return (
    <div className={bodyCls}>
      <FilterModule
        title={'Вы управляете следующими аккаунтами'}
        withUserStatus
        withSearch
        withDateInterval
        className='list-data-wrapper__filters'
      />
      {isMobile ? (
        <ModeratorsMobile
          moderatorsList={moderatorsList}
          handleRowClick={handleRowClick}
        />
      ) : (
        <ModeratorsTable
          moderatorsList={moderatorsList}
          handleRowClick={handleRowClick}
        />
      )}
      <div className='list-data-wrapper-actions'>
        <Button
          className='list-data-wrapper-actions__btn'
          onClick={goToCreateModerator}
        >
          Добавить аккаунт
        </Button>
        {pageCount > 1 && (
          <Pagination
            className='list-data-wrapper-actions__pagination'
            activeId={`${currentPage - 1}`}
            onChange={handleChangePage}
            count={pageCount}
          />
        )}
      </div>
    </div>
  )
}
