import React from 'react'
import { COLUMNS } from './CategoriesTable-constants'
import { Table } from '../../../../components/Table'
import { CategoriesType } from '../../CategoriesPage-types'

interface CategoriesTableProps {
  categoriesList: CategoriesType
  handleRowClick: (id: string) => void
}

export const CategoriesTable: React.FC<CategoriesTableProps> = ({
  categoriesList,
  handleRowClick,
}) => {
  const columns = React.useMemo(() => COLUMNS, []) as any
  const data = React.useMemo(() => categoriesList, [categoriesList])

  return <Table columns={columns} data={data} handleRowClick={handleRowClick} />
}
