import React from 'react'
import { Cell } from 'react-table'
import { Table } from '../../../../components/Table'
import { getNormalizeDate } from '../../../../utils/utils'
import {
  SearchHistoryArrayType,
  SearchHistoryType,
} from '../../SearchHistoryPage-types'

const COLUMNS = [
  {
    Header: 'Потребитель',
    accessor: 'consumer',
    Cell: (cellInfo: Cell<SearchHistoryType>) => {
      return cellInfo.row.original.consumer?.name || '-'
    },
  },
  {
    Header: 'Категория',
    accessor: 'category',
    Cell: (cellInfo: Cell<SearchHistoryType>) => {
      return cellInfo.row.original.category?.category || '-'
    },
  },
  {
    Header: 'Теги',
    accessor: 'tags',
    Cell: (cellInfo: Cell<SearchHistoryType>) => {
      return cellInfo.row.original?.tags
        ? cellInfo.row.original.tags.join(',')
        : '-'
    },
  },
  {
    Header: 'Запрос',
    accessor: 'search',
    Cell: (cellInfo: Cell<SearchHistoryType>) => {
      return cellInfo.row.original?.search
        ? cellInfo.row.original.search.join(',')
        : '-'
    },
  },
  {
    Header: 'Поставщик',
    accessor: 'сcontributor',
    Cell: (cellInfo: Cell<SearchHistoryType>) => {
      return cellInfo.row.original.contributor?.name || '-'
    },
  },
  {
    Header: 'Дата поиска',
    accessor: 'registerDate',
    Cell: (cellInfo: Cell<SearchHistoryType>) => {
      return getNormalizeDate(cellInfo.row.original?.searchDate)
    },
  },
]

interface RequestsTableProps {
  searchHistoryList: SearchHistoryArrayType
  handleClick: (id: string) => void
}

export const SearchHistoryTable: React.FC<RequestsTableProps> = ({
  searchHistoryList,
  handleClick,
}) => {
  const columns = React.useMemo(() => COLUMNS, []) as any
  const data = React.useMemo(() => searchHistoryList, [searchHistoryList])

  return <Table columns={columns} data={data} handleRowClick={handleClick} />
}
