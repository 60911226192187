import classNames from 'classnames'
import React from 'react'
import queryString from 'query-string'
import { Button } from '../../../../components/Button'
import { IncorretResult } from '../../../../components/IncorretResult'
import { Pagination } from '../../../../components/Pagination'
import { isMobileDevice } from '../../../../utils/check'
import { ADMINS_SIZE } from '../../AdminsPage-constants'
import { useFetchAdmins } from '../../AdminsPage-hooks'
import { AdminAPIAnswerType } from '../../AdminsPage-types'
import { AdministatorsTable } from '../AdministatorsTable'
import { AdministratorsMobile } from '../AdministratorsMobile'
import { FilterModule } from '../../../../modules/FilterModule'
import { SkeletonBody } from '../../../../components/SkeletonBody'
import { useURLParams } from '../../../../hooks/useURLParams'
import { useNavigate } from 'react-router'
import {
  INTERVAL_URL_VALUE,
  NAME_URL_VALUE,
  PAGE_URL_VALUE,
  USER_STATUS_URL_VALUE,
} from '../../../../constants/filterURLNames'

export const Administrators = () => {
  const navigate = useNavigate()
  const urlParams = useURLParams()
  const interval = urlParams[INTERVAL_URL_VALUE]
  const name = urlParams[NAME_URL_VALUE]
  const status = urlParams[USER_STATUS_URL_VALUE]
  const page = urlParams[PAGE_URL_VALUE]
  const currentPage = page ? +page : 1

  const isMobile = isMobileDevice()

  const {
    data: adminsRequestData,
    isLoading,
    isFetching,
    isError,
  } = useFetchAdmins(currentPage, status, interval, name) as {
    data: AdminAPIAnswerType
    isLoading: boolean
    isFetching: boolean
    isError: boolean
  }

  const adminsList = React.useMemo(
    () => adminsRequestData?.items,
    [adminsRequestData]
  )

  const total = adminsRequestData?.total

  const pageCount = React.useMemo(() => {
    return Math.ceil(total / ADMINS_SIZE)
  }, [total])

  if (isLoading) {
    const mode = isMobile === true ? 'windows' : 'table'
    return (
      <SkeletonBody
        mode={mode}
        columnsCount={8}
        actionMode={'all'}
        withFilters={true}
      />
    )
  }
  if (isError) {
    return <IncorretResult />
  }

  const handleRowClick = (id: string) => {
    navigate(`${id}/edit`)
  }

  const handleChangePage = (page: string) => {
    urlParams[PAGE_URL_VALUE] = (+page + 1).toString()
    navigate({ search: queryString.stringify(urlParams) })
  }

  const goToNewAdmin = () => {
    navigate('new')
  }

  const bodyCls = classNames('list-data-wrapper', {
    'list-data-wrapper_fetching': isFetching === true,
  })

  return (
    <div className={bodyCls}>
      <FilterModule
        title={'Вы управляете следующими аккаунтами'}
        withUserStatus
        withSearch
        withDateInterval
        className='list-data-wrapper__filters'
      />
      {isMobile ? (
        <AdministratorsMobile
          adminsList={adminsList}
          handleRowClick={handleRowClick}
        />
      ) : (
        <AdministatorsTable
          adminsList={adminsList}
          handleRowClick={handleRowClick}
        />
      )}
      <div className='list-data-wrapper-actions'>
        <Button
          className='list-data-wrapper-actions__btn'
          onClick={goToNewAdmin}
        >
          Добавить аккаунт
        </Button>
        {pageCount > 1 && (
          <Pagination
            className='list-data-wrapper-actions__pagination'
            activeId={`${currentPage - 1}`}
            onChange={handleChangePage}
            count={pageCount}
          />
        )}
      </div>
    </div>
  )
}
