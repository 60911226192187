import React from 'react'
import { Table } from '../../../../components/Table'
import { getNormalizeDate, getOrderStatus } from '../../../../utils/utils'
import { OrdersType } from '../../OrdersPage-types'

const COLUMNS = [
  {
    Header: 'Поставщик',
    accessor: 'contributor',
    Cell: (cellInfo: any) => {
      return cellInfo.row.values?.contributor?.name ?? '-'
    },
  },
  {
    Header: 'Модератор',
    accessor: 'moderator',
    Cell: (cellInfo: any) => {
      return cellInfo.row.values?.moderator?.name ?? '-'
    },
  },
  {
    Header: 'Сумма вывода',
    accessor: 'outgo',
  },
  {
    Header: 'Дата заявки',
    accessor: 'createDate',
    Cell: (cellInfo: any) => {
      return getNormalizeDate(cellInfo.row.values?.createDate)
    },
  },
  {
    Header: 'Статус',
    accessor: 'status',
    Cell: (cellInfo: any) => {
      return getOrderStatus(cellInfo.row.values?.status)
    },
  },
]

interface OrdersTableProps {
  ordersList: OrdersType
  handleRowClick: (id: string) => void
}

export const OrdersTable: React.FC<OrdersTableProps> = ({
  ordersList,
  handleRowClick,
}) => {
  const columns = React.useMemo(() => COLUMNS, []) as any
  const data = React.useMemo(() => ordersList, [ordersList])
  return (
    <Table
      columns={columns}
      data={data}
      handleRowClick={handleRowClick}
      // className='table-wrapper__table'
    />
  )
}
