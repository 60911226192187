import classNames from 'classnames'
import React from 'react'
import queryString from 'query-string'
import { Button } from '../../../../components/Button'
import { IncorretResult } from '../../../../components/IncorretResult'
import { Pagination } from '../../../../components/Pagination'
import { isMobileDevice } from '../../../../utils/check'
import { CATEGORIES_SIZE } from '../../CategoriesPage-constants'
import { useFetchCategories } from '../../CategoriesPage-hooks'
import { CategoryAPIAnswerType } from '../../CategoriesPage-types'
import { CategoriesMobile } from '../CategoriesMobile'
import { CategoriesTable } from '../CategoriesTable'
import { SkeletonBody } from '../../../../components/SkeletonBody'
import { useURLParams } from '../../../../hooks/useURLParams'
import { useNavigate } from 'react-router'
import { Title } from '../../../../components/Title'
import { PAGE_URL_VALUE } from '../../../../constants/filterURLNames'

export const Categories = () => {
  const navigate = useNavigate()
  const urlParams = useURLParams()
  const page = urlParams[PAGE_URL_VALUE]
  const currentPage = page ? +page : 1

  const isMobile = isMobileDevice()
  const {
    data: categoriesRequestData,
    isFetching,
    isLoading,
    isError,
  } = useFetchCategories(currentPage) as {
    data: CategoryAPIAnswerType
    isFetching: boolean
    isLoading: boolean
    isError: boolean
  }

  const categoriesList = React.useMemo(
    () => categoriesRequestData?.items,
    [categoriesRequestData]
  )
  const total = categoriesRequestData?.total
  const pageCount = React.useMemo(() => {
    return Math.ceil(total / CATEGORIES_SIZE)
  }, [total])

  const handleRowClick = (id: string) => {
    navigate(`${id}/edit`)
  }

  const handleChangePage = (page: string) => {
    urlParams[PAGE_URL_VALUE] = (+page + 1).toString()
    navigate({ search: queryString.stringify(urlParams) })
  }

  const goToNewCategory = () => {
    navigate(`new`)
  }

  if (isLoading) {
    const mode = isMobile === true ? 'windows' : 'table'
    return (
      <SkeletonBody
        mode={mode}
        columnsCount={1}
        actionMode={'all'}
        withFilters={true}
      />
    )
  }
  if (isError) {
    return <IncorretResult />
  }

  const bodyCls = classNames('list-data-wrapper', {
    'list-data-wrapper_fetching': isFetching === true,
  })

  return (
    <div className={bodyCls}>
      <Title text='Категории материала' />
      {isMobile ? (
        <CategoriesMobile
          categoriesList={categoriesList}
          handleRowClick={handleRowClick}
        />
      ) : (
        <CategoriesTable
          categoriesList={categoriesList}
          handleRowClick={handleRowClick}
        />
      )}
      <div className='list-data-wrapper-actions'>
        <Button
          className='list-data-wrapper-actions__btn'
          onClick={goToNewCategory}
        >
          Добавить категорию
        </Button>
        {pageCount > 1 && (
          <Pagination
            className='list-data-wrapper-actions__pagination'
            activeId={`${currentPage - 1}`}
            onChange={handleChangePage}
            count={pageCount}
          />
        )}
      </div>
    </div>
  )
}
