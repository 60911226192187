import React from 'react'
import { Table } from '../../../../components/Table'
import { RejectionsType } from '../../../../types/common'
import { COLUMNS } from './RejectionsTable-constants'

interface RejectionsTableProps {
  rejectionsList: RejectionsType
  handleRowClick: (id: string) => void
}

export const RejectionsTable: React.FC<RejectionsTableProps> = ({
  rejectionsList,
  handleRowClick,
}) => {
  const columns = React.useMemo(() => COLUMNS, []) as any
  const data = React.useMemo(() => rejectionsList, [rejectionsList])

  return (
    <Table
      columns={columns}
      data={data}
      handleRowClick={handleRowClick}
      className='table-wrapper__table'
    />
  )
}
