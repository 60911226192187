import React from 'react'
import { Cell } from 'react-table'
import { Table } from '../../../../components/Table'
import { getNormalizeDate } from '../../../../utils/utils'
import { ContributorGroupType } from '../../ContributorGroupPage-types'
import './GroupsTable.sass'

export const COLUMNS = [
  {
    Header: 'Название',
    accessor: 'groupName',
  },
  {
    Header: 'Участников',
    accessor: 'membersCount',
  },
  {
    Header: 'Начало контракта',
    accessor: 'registerDate',
    Cell: (cellInfo: Cell<ContributorGroupType>) => {
      return getNormalizeDate(cellInfo.row.original?.contract?.startDate)
    },
  },
]

interface GroupsTableProps {
  className?: string
  groupList: ContributorGroupType[]
  handleRowClick: (id: string) => void
}

export const GroupsTable: React.FC<GroupsTableProps> = ({
  groupList,
  handleRowClick,
}) => {
  const columns = React.useMemo(() => COLUMNS, []) as any
  const data = React.useMemo(() => groupList, [groupList])

  return (
    <Table
      columns={columns}
      data={data}
      handleRowClick={handleRowClick}
      className='table-wrapper__table'
    />
  )
}
