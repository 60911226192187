import React from 'react'
import { Table } from '../../../../components/Table'
import { ContributorsType } from '../../../../types/contributor'
import { getNormalizeDate, getStatus } from '../../../../utils/utils'

const COLUMNS = [
  {
    Header: 'Логин',
    accessor: 'login',
  },
  {
    Header: 'Имя',
    accessor: 'name',
  },
  {
    Header: 'Зарегистрирован',
    accessor: 'registerBy',
    Cell: (cellInfo: any) => {
      return cellInfo.row.values?.registerBy?.name ?? 'Неизвестно'
    },
  },
  {
    Header: 'Дата регистрации',
    accessor: 'registerDate',
    Cell: (cellInfo: any) => {
      return getNormalizeDate(cellInfo.row.original?.registerDate)
    },
  },
  {
    Header: 'Статус',
    accessor: 'status',
    Cell: (cellInfo: any) => {
      return cellInfo.row.values?.status === 'active' ? (
        <span className='cell_green'>
          {getStatus(cellInfo.row.values?.status)}
        </span>
      ) : (
        <span className='cell_red'>
          {getStatus(cellInfo.row.values?.status)}
        </span>
      )
    },
  },
]

interface ContributorsTableProps {
  contributorsList: ContributorsType
  handleRowClick: (id: string) => void
}

export const ContributorsTable: React.FC<ContributorsTableProps> = ({
  contributorsList,
  handleRowClick,
}) => {
  const columns = React.useMemo(() => COLUMNS, []) as any
  const data = React.useMemo(() => contributorsList, [contributorsList])
  return (
    <Table
      columns={columns}
      data={data}
      colored
      handleRowClick={handleRowClick}
    />
  )
}
