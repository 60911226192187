import classNames from 'classnames'
import React from 'react'
import queryString from 'query-string'
import { Button } from '../../../../components/Button'
import { IncorretResult } from '../../../../components/IncorretResult'
import { Pagination } from '../../../../components/Pagination'
import { isMobileDevice } from '../../../../utils/check'
import { REJECTIONS_SIZE } from '../../RejectionsPage-constants'
import { useFetchRejections } from '../../RejectionsPage-hooks'
import { RejectionAPIAnswerType } from '../../RejectionsPage-types'
import { RejectionsMobile } from '../RejectionsMobile'
import { RejectionsTable } from '../RejectionsTable'
import { SkeletonBody } from '../../../../components/SkeletonBody'
import { useNavigate } from 'react-router'
import { useURLParams } from '../../../../hooks/useURLParams'
import { Title } from '../../../../components/Title'
import { PAGE_URL_VALUE } from '../../../../constants/filterURLNames'

export const Rejections = () => {
  const navigate = useNavigate()
  const urlParams = useURLParams()
  const page = urlParams[PAGE_URL_VALUE]
  const currentPage = page ? +page : 1
  const isMobile = isMobileDevice()

  const {
    data: rejectionsRequestData,
    isLoading,
    isFetching,
    isError,
  } = useFetchRejections(currentPage) as {
    data: RejectionAPIAnswerType
    isLoading: boolean
    isFetching: boolean
    isError: boolean
  }

  const rejectionsList = React.useMemo(
    () => rejectionsRequestData?.items,
    [rejectionsRequestData]
  )
  const total = rejectionsRequestData?.total
  const pageCount = React.useMemo(() => {
    return Math.ceil(total / REJECTIONS_SIZE)
  }, [total])

  const handleRowClick = (id: string) => {
    navigate(`${id}/edit`)
  }

  const handleChangePage = (page: string) => {
    urlParams[PAGE_URL_VALUE] = (+page + 1).toString()
    navigate({ search: queryString.stringify(urlParams) })
  }
  const handleAddRejection = () => {
    goToNewRejection()
  }
  const goToNewRejection = () => {
    navigate(`new`)
  }

  if (isLoading) {
    const mode = isMobile === true ? 'windows' : 'table'
    return (
      <SkeletonBody
        mode={mode}
        columnsCount={1}
        actionMode={'all'}
        withFilters={true}
      />
    )
  }
  if (isError) {
    return <IncorretResult />
  }

  const bodyCls = classNames('list-data-wrapper', {
    'list-data-wrapper_fetching': isFetching === true,
  })

  return (
    <div className={bodyCls}>
      <Title text='Причины отказа' />
      {isMobile ? (
        <RejectionsMobile
          rejectionsList={rejectionsList}
          handleRowClick={handleRowClick}
        />
      ) : (
        <RejectionsTable
          rejectionsList={rejectionsList}
          handleRowClick={handleRowClick}
        />
      )}
      <div className='list-data-wrapper-actions'>
        <Button
          className='list-data-wrapper-actions__btn'
          onClick={handleAddRejection}
        >
          Добавить причину
        </Button>
        {pageCount > 1 && (
          <Pagination
            className='list-data-wrapper-actions__pagination'
            activeId={`${currentPage - 1}`}
            onChange={handleChangePage}
            count={pageCount}
          />
        )}
      </div>
    </div>
  )
}
