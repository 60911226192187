import React from 'react'
import { Table } from '../../../../components/Table'
import { ConsumersListType } from '../../../../types/consumer'
import { getNormalizeDate, getStatus } from '../../../../utils/utils'

export const COLUMNS = [
  {
    Header: 'Логин',
    accessor: 'login',
  },
  {
    Header: 'Имя',
    accessor: 'name',
  },
  {
    Header: 'Зарегистрирован',
    accessor: 'registerBy',
    Cell: (cellInfo: any) => {
      return cellInfo.row.values?.registerBy?.name ?? 'Неизвестно'
    },
  },
  {
    Header: 'Дата регистрации',
    accessor: 'registerDate',
    Cell: (cellInfo: any) => {
      return getNormalizeDate(cellInfo.row.original?.registerDate)
    },
  },
  {
    Header: 'Лимит',
    accessor: 'limit',
  },
  {
    Header: 'Статус',
    accessor: 'status',
    Cell: (cellInfo: any) => {
      return cellInfo.row.values?.status === 'active' ? (
        <span className='cell_green'>
          {getStatus(cellInfo.row.values.status)}
        </span>
      ) : (
        <span className='cell_red'>
          {getStatus(cellInfo.row.values?.status)}
        </span>
      )
    },
  },
]

interface ConsumersTableProps {
  consumersList: ConsumersListType
  handleRowClick: (id: string) => void
}

export const ConsumersTable: React.FC<ConsumersTableProps> = ({
  consumersList,
  handleRowClick,
}) => {
  const columns = React.useMemo(() => COLUMNS, []) as any
  const data = React.useMemo(() => consumersList, [consumersList])

  return (
    <Table
      columns={columns}
      data={data}
      handleRowClick={handleRowClick}
      className='table-wrapper__table'
    />
  )
}
