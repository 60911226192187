import React from 'react'
import { Table } from '../../../../components/Table'
import {
  getNormalizeDate,
  getOrderStatus,
  getUserType,
} from '../../../../utils/utils'
import { RequestsType } from '../../RequestsPage-types'

const COLUMNS = [
  {
    Header: 'Логин',
    accessor: 'login',
  },
  {
    Header: 'Имя',
    accessor: 'name',
  },
  {
    Header: 'Дата регистрации',
    accessor: 'registerDate',
    Cell: (cellInfo: any) => {
      return getNormalizeDate(cellInfo.row.original?.registerDate)
    },
  },
  {
    Header: 'Тип пользователя',
    accessor: 'userType',
    Cell: (cellInfo: any) => {
      return getUserType(cellInfo.row.original?.userType)
    },
  },
  {
    Header: 'Статус',
    accessor: 'status',
    Cell: (cellInfo: any) => {
      return getOrderStatus(cellInfo.row.original?.status)
    },
  },
]

interface RequestsTableProps {
  requestsList: RequestsType
  handleRowClick: (id: string | number) => void
}

export const RequestsTable: React.FC<RequestsTableProps> = ({
  requestsList,
  handleRowClick,
}) => {
  const columns = React.useMemo(() => COLUMNS, []) as any
  const data = React.useMemo(() => requestsList, [requestsList])

  return <Table columns={columns} data={data} handleRowClick={handleRowClick} />
}
