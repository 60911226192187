import classNames from 'classnames'
import React from 'react'
import queryString from 'query-string'
import { IncorretResult } from '../../../../components/IncorretResult'
import { Pagination } from '../../../../components/Pagination'
import { isMobileDevice } from '../../../../utils/check'
import { SearchHistoryMobile } from '../SearchHistoryMobile'
import { SearchHistoryTable } from '../SearchHistoryTable'
import { FilterModule } from '../../../../modules/FilterModule'
import { SkeletonBody } from '../../../../components/SkeletonBody'
import { useNavigate } from 'react-router'
import { useURLParams } from '../../../../hooks/useURLParams'
import { useFetchSearchHistory } from '../../SearchHistoryPage-hooks'
import { SEARCH_HISTORY_SIZE } from '../../SearchHistoryPage-constants'
import { SearchHistoryAPIAnswerType } from '../../SearchHistoryPage-types'
import {
  CONTRIBUTOR_URL_VALUE,
  CATEGORY_URL_VALUE,
  CONSUMER_USER_URL_TYPE,
  INTERVAL_URL_VALUE,
  PAGE_URL_VALUE,
} from '../../../../constants/filterURLNames'
import { DOMAIN_PATH } from '../../../../constants/globalConstants'

export const SearchHistory = () => {
  const navigate = useNavigate()
  const urlParams = useURLParams()
  const interval = urlParams[INTERVAL_URL_VALUE]
  const page = urlParams[PAGE_URL_VALUE]
  const category = urlParams[CATEGORY_URL_VALUE]
  const author = urlParams[CONTRIBUTOR_URL_VALUE]
  const isConsumer = Boolean(urlParams[CONSUMER_USER_URL_TYPE])
  const currentPage = page ? +page : 1

  const isMobile = isMobileDevice()

  const {
    data: searchHistoryData,
    isLoading,
    isFetching,
    isError,
  } = useFetchSearchHistory(
    currentPage,
    interval,
    category,
    author,
    isConsumer
  ) as {
    data: SearchHistoryAPIAnswerType
    isLoading: boolean
    isFetching: boolean
    isError: boolean
  }

  const searchHistoryList = React.useMemo(
    () => searchHistoryData?.items,
    [searchHistoryData]
  )
  const total = searchHistoryData?.total
  const pageCount = React.useMemo(() => {
    return Math.ceil(total / SEARCH_HISTORY_SIZE)
  }, [total])

  const handleChangePage = (page: string) => {
    urlParams[PAGE_URL_VALUE] = (+page + 1).toString()
    navigate({ search: queryString.stringify(urlParams) })
  }

  const handleClick = (id: string) => {
    const currentDataRow = searchHistoryList[+id]
    const paramObject = {
      contributorId: currentDataRow.contributor
        ? currentDataRow.contributor.id
        : undefined,
      tags: currentDataRow.tags ? currentDataRow.tags : undefined,
      search: currentDataRow.search ? currentDataRow.search : undefined,
      categories: currentDataRow.category
        ? currentDataRow.category.id
        : undefined,
    }
    const params = queryString.stringify(paramObject, {
      arrayFormat: 'separator',
      arrayFormatSeparator: ' ',
    })
    window.open(`https://${DOMAIN_PATH}/search?${params}`, '_blank')
  }

  if (isLoading) {
    const mode = isMobile === true ? 'windows' : 'table'
    return (
      <SkeletonBody
        mode={mode}
        columnsCount={5}
        actionMode={'pagination'}
        withFilters={true}
      />
    )
  }
  if (isError) {
    return <IncorretResult />
  }

  const bodyCls = classNames('list-data-wrapper', {
    'list-data-wrapper_fetching': isFetching === true,
  })

  return (
    <div className={bodyCls}>
      <FilterModule
        title={'История поиска'}
        withCategory={true}
        withDateInterval={true}
        withConsumerType={true}
        className='list-data-wrapper__filters'
      />
      {isMobile ? (
        <SearchHistoryMobile
          searchHistoryList={searchHistoryList}
          handleClick={handleClick}
        />
      ) : (
        <SearchHistoryTable
          searchHistoryList={searchHistoryList}
          handleClick={handleClick}
        />
      )}
      <div className='list-data-wrapper-actions'>
        {pageCount > 1 && (
          <Pagination
            className='list-data-wrapper-actions__pagination'
            activeId={`${currentPage - 1}`}
            onChange={handleChangePage}
            count={pageCount}
          />
        )}
      </div>
    </div>
  )
}
