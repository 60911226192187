import React from 'react'
import { Table } from '../../../../components/Table'
import { getMaterialStatus, getNormalizeDate } from '../../../../utils/utils'
import { ModerationsHistoryType } from '../../ModerationHistoryPage-types'

const COLUMNS = [
  {
    Header: 'Дата загрузки',
    accessor: 'material.uploadDate',
    Cell: (cellInfo: any) => {
      return getNormalizeDate(cellInfo.row.original.material?.uploadDate)
    },
  },
  {
    Header: 'Автор',
    accessor: 'material.contributor.name',
  },
  {
    Header: 'Начало модерации',
    accessor: 'moderateStart',
    Cell: (cellInfo: any) => {
      return getNormalizeDate(cellInfo.row.original?.moderateStart)
    },
  },
  {
    Header: 'Модератор',
    accessor: 'moderatedBy.name',
  },
  {
    Header: 'Статус',
    accessor: 'status',
    Cell: (cellInfo: any) => {
      return cellInfo.row.original?.status === 'accept' ? (
        <span className='cell_green'>
          {getMaterialStatus(cellInfo.row.original.status)}
        </span>
      ) : (
        <span className='cell_red'>
          {getMaterialStatus(cellInfo.row.original.status)}
        </span>
      )
    },
  },
]

interface ModerationHistoryTableProps {
  moderationHistoryList: ModerationsHistoryType
  handleRowClick: (id: string) => void
}

export const ModerationHistoryTable: React.FC<ModerationHistoryTableProps> = ({
  moderationHistoryList,
  handleRowClick,
}) => {
  const columns = React.useMemo(() => COLUMNS, []) as any
  const data = React.useMemo(
    () => moderationHistoryList,
    [moderationHistoryList]
  )

  return (
    <Table
      columns={columns}
      data={data}
      handleRowClick={handleRowClick}
      className='table-wrapper__table'
    />
  )
}
